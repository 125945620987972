import React from 'react';


export const Footer = ({images,urlPrefix}) => {

  const prefix = (urlPrefix!==undefined) ? urlPrefix : console.log("urlPrefix not defined");
  return (
    <footer className="footer">
        <div className="container">
            <div className="row gap-y align-items-center">

                <div className="col-6 col-lg-3">
                    <a href={`${prefix}/`}><img src={images.AssetsImgLogodark1Png} alt="logo"/></a>
                </div>

                <div className="col-6 col-lg-3 text-right order-lg-last">
                    <div className="social">

                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="nav nav-bold nav-uppercase nav-trim justify-content-lg-center">
                        <a className="nav-link" href="uikit/index.html">Elements</a>
                        <a className="nav-link" href="block/index.html">Blocks</a>
                        <a className="nav-link" href={`${prefix}/AboutUs`}>About</a>
                        <a className="nav-link" href="blog/grid.html">Blog</a>
                        <a className="nav-link" href="page/contact-1.html">Contact</a>
                    </div>
                </div>

            </div>
        </div>
    </footer>

  );

}