import React from 'react';
import Slider from 'react-slick';

export const Slider2 = () => {

  const settings = {
        arrows:false,
        dots: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        swipeToSlide: true,



      };


  return (
    <Slider className="row gap-y" {...settings}>

        <div className="p-5" style={{width:"100%", display:
            "inline-block"}}>
            <div className="card border hover-shadow-6">
                <div className="card-body px-5">
                    <div className="row">
                        <div className="col-auto mr-auto">
                            <h6><strong>Code Quality</strong></h6>
                        </div>

                        <div className="col-auto">
                            <div className="rating mb-3">
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                            </div>
                        </div>
                    </div>

                    <p>The BEST template we have bought on Themeforest in terms of design and code
                        quality. Can't wait for their admin template!</p>
                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
                                                                   href="https://themeforest.net/ratings/2653514"
                                                                   tabIndex="-1"><em>KungWoo</em></a>
                    </p>

                </div>
            </div>
        </div>

        <div className="p-5" style={{width:"100%", display:
              "inline-block"}}>
              <div className="card border hover-shadow-6">
                  <div className="card-body px-5">
                      <div className="row">
                          <div className="col-auto mr-auto">
                              <h6><strong>Customer Support</strong></h6>
                          </div>

                          <div className="col-auto">
                              <div className="rating mb-3">
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                              </div>
                          </div>
                      </div>

                      <p>TheThemeio is the best coder, which we ever worked in themeforest. Fast and on-demand
                          email support. Good and quality work. Thank you for your help.</p>
                      <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
                                                                     href="https://themeforest.net/ratings/2646286"
                                                                     tabIndex="-1"><em>webserviz</em></a></p>

                  </div>
              </div>
          </div>

        <div className="p-5" style={{width:"100%", display:
            "inline-block"}}>
            <div className="card border hover-shadow-6">
                <div className="card-body px-5">
                    <div className="row">
                        <div className="col-auto mr-auto">
                            <h6><strong>Design Quality</strong></h6>
                        </div>

                        <div className="col-auto">
                            <div className="rating mb-3">
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                            </div>
                        </div>
                    </div>

                    <p>Superb, comprehensive, professional. And most importantly, it makes me look good! Thank
                        you.</p>
                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
                                                                   href="https://themeforest.net/ratings/2784705"
                                                                   tabIndex="0"><em>coolrebel</em></a></p>

                </div>
            </div>
        </div>


        <div className="p-5" style={{width:"100%", display:
            "inline-block"}}>
            <div className="card border hover-shadow-6">
                <div className="card-body px-5">
                    <div className="row">
                        <div className="col-auto mr-auto">
                            <h6><strong>Code Quality</strong></h6>
                        </div>

                        <div className="col-auto">
                            <div className="rating mb-3">
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                            </div>
                        </div>
                    </div>

                    <p>Really well organized code, responsive customer service. excellent value for money.
                        Recommended 100%</p>
                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
                                                                   href="https://themeforest.net/ratings/2739368"
                                                                   tabIndex="0"><em>yorickgroeven</em></a></p>

                </div>
            </div>
        </div>


        <div className="p-5" style={{width:"100%", display:
              "inline-block"}}>
              <div className="card border hover-shadow-6">
                  <div className="card-body px-5">
                      <div className="row">
                          <div className="col-auto mr-auto">
                              <h6><strong>Code Quality</strong></h6>
                          </div>

                          <div className="col-auto">
                              <div className="rating mb-3">
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                              </div>
                          </div>
                      </div>

                      <p>Excellent design and code quality. Infinite variations to be combined. Congrats for this
                          job!</p>
                      <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
                                                                     href="https://themeforest.net/ratings/2710320"
                                                                     tabIndex="-1"><em>epino</em></a></p>

                  </div>
              </div>
          </div>



          <div className="p-5" style={{width:"100%", display:
              "inline-block"}}>
              <div className="card border hover-shadow-6">
                  <div className="card-body px-5">
                      <div className="row">
                          <div className="col-auto mr-auto">
                              <h6><strong>Documentation Quality</strong></h6>
                          </div>

                          <div className="col-auto">
                              <div className="rating mb-3">
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                                  <label className="fa fa-star active"></label>
                              </div>
                          </div>
                      </div>

                      <p>Really good template, easy to customize with a really good documentation. Many different
                          example of pages.</p>
                      <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
                                                                     href="https://themeforest.net/ratings/2686598"
                                                                     tabIndex="-1"><em>pepsit36</em></a></p>

                  </div>
              </div>
          </div>

        <div className="p-5" style={{width:"100%", display:
            "inline-block"}}>
            <div className="card border hover-shadow-6">
                <div className="card-body px-5">
                    <div className="row">
                        <div className="col-auto mr-auto">
                            <h6><strong>Design Quality</strong></h6>
                        </div>

                        <div className="col-auto">
                            <div className="rating mb-3">
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                            </div>
                        </div>
                    </div>

                    <p>The theme and customer support are both top-tier! Thank you for saving us 30+ hours of
                        work.</p>
                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
                                                                   href="https://themeforest.net/ratings/2683519"
                                                                   tabIndex="-1"><em>Its_Complete</em></a></p>

                </div>
            </div>
        </div>


        <div className="p-5" style={{width:"100%", display:
            "inline-block"}}>
            <div className="card border hover-shadow-6">
                <div className="card-body px-5">
                    <div className="row">
                        <div className="col-auto mr-auto">
                            <h6><strong>Code Quality</strong></h6>
                        </div>

                        <div className="col-auto">
                            <div className="rating mb-3">
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                            </div>
                        </div>
                    </div>

                    <p>The BEST template we have bought on Themeforest in terms of design and code quality. Can't
                        wait for their admin template!</p>
                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
                                                                   href="https://themeforest.net/ratings/2653514"
                                                                   tabIndex="-1"><em>KungWoo</em></a></p>

                </div>
            </div>
        </div>

        <div className="p-5" style={{width:"100%", display:
            "inline-block"}}>
            <div className="card border hover-shadow-6">
                <div className="card-body px-5">
                    <div className="row">
                        <div className="col-auto mr-auto">
                            <h6><strong>Customer Support</strong></h6>
                        </div>

                        <div className="col-auto">
                            <div className="rating mb-3">
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                                <label className="fa fa-star active"></label>
                            </div>
                        </div>
                    </div>

                    <p>TheThemeio is the best coder, which we ever worked in themeforest. Fast and on-demand email
                        support. Good and quality work. Thank you for your help.</p>
                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
                                                                   href="https://themeforest.net/ratings/2646286"
                                                                   tabIndex="-1"><em>webserviz</em></a></p>

                </div>
            </div>
        </div>

    </Slider>
  );




//  return (
//<div data-provide="slider" data-dots="true" data-autoplay="false" data-slides-to-show="2"
//             className="slick-initialized slick-slider slick-dotted">
//            <div className="slick-list draggable">
//                <div className="slick-track" style={{opacity:"1", width:
//                "8880px", transform:"translate3d(-1110px, 0px, 0px)"}}>
//                <div className="slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true"
//                     style={{width:"555px"}} tabIndex="-1">
//                    <div>
//                        <div className="p-5" style={{width:"100%", display:
//                        "inline-block"}}>
//                        <div className="card border hover-shadow-6">
//                            <div className="card-body px-5">
//                                <div className="row">
//                                    <div className="col-auto mr-auto">
//                                        <h6><strong>Code Quality</strong></h6>
//                                    </div>
//
//                                    <div className="col-auto">
//                                        <div className="rating mb-3">
//                                            <label className="fa fa-star active"></label>
//                                            <label className="fa fa-star active"></label>
//                                            <label className="fa fa-star active"></label>
//                                            <label className="fa fa-star active"></label>
//                                            <label className="fa fa-star active"></label>
//                                        </div>
//                                    </div>
//                                </div>
//
//                                <p>The BEST template we have bought on Themeforest in terms of design and code
//                                    quality. Can't wait for their admin template!</p>
//                                <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                               href="https://themeforest.net/ratings/2653514"
//                                                                               tabIndex="-1"><em>KungWoo</em></a>
//                                </p>
//
//                            </div>
//                        </div>
//                    </div>
//                </div>
//            </div>
//            <div className="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true"
//                 style={{width:"555px"}} tabIndex="-1">
//                <div>
//                    <div className="p-5" style={{width:"100%", display:
//                    "inline-block"}}>
//                    <div className="card border hover-shadow-6">
//                        <div className="card-body px-5">
//                            <div className="row">
//                                <div className="col-auto mr-auto">
//                                    <h6><strong>Customer Support</strong></h6>
//                                </div>
//
//                                <div className="col-auto">
//                                    <div className="rating mb-3">
//                                        <label className="fa fa-star active"></label>
//                                        <label className="fa fa-star active"></label>
//                                        <label className="fa fa-star active"></label>
//                                        <label className="fa fa-star active"></label>
//                                        <label className="fa fa-star active"></label>
//                                    </div>
//                                </div>
//                            </div>
//
//                            <p>TheThemeio is the best coder, which we ever worked in themeforest. Fast and on-demand
//                                email support. Good and quality work. Thank you for your help.</p>
//                            <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                           href="https://themeforest.net/ratings/2646286"
//                                                                           tabIndex="-1"><em>webserviz</em></a></p>
//
//                        </div>
//                    </div>
//                </div>
//            </div>
//        </div>
//        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false"
//             style={{width:"555px"}} role="tabpanel" id="slick-slide10">
//            <div>
//                <div className="p-5" style={{width:"100%", display:
//                "inline-block"}}>
//                <div className="card border hover-shadow-6">
//                    <div className="card-body px-5">
//                        <div className="row">
//                            <div className="col-auto mr-auto">
//                                <h6><strong>Design Quality</strong></h6>
//                            </div>
//
//                            <div className="col-auto">
//                                <div className="rating mb-3">
//                                    <label className="fa fa-star active"></label>
//                                    <label className="fa fa-star active"></label>
//                                    <label className="fa fa-star active"></label>
//                                    <label className="fa fa-star active"></label>
//                                    <label className="fa fa-star active"></label>
//                                </div>
//                            </div>
//                        </div>
//
//                        <p>Superb, comprehensive, professional. And most importantly, it makes me look good! Thank
//                            you.</p>
//                        <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                       href="https://themeforest.net/ratings/2784705"
//                                                                       tabIndex="0"><em>coolrebel</em></a></p>
//
//                    </div>
//                </div>
//            </div>
//        </div>
//    </div>
//    <div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{width:"555px"}}
//         role="tabpanel" id="slick-slide11">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Code Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>Really well organized code, responsive customer service. excellent value for money.
//                        Recommended 100%</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2739368"
//                                                                   tabIndex="0"><em>yorickgroeven</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide" data-slick-index="2" aria-hidden="true" style={{width:"555px"}} tabIndex="-1"
//         role="tabpanel" id="slick-slide12">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Code Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>Excellent design and code quality. Infinite variations to be combined. Congrats for this
//                        job!</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2710320"
//                                                                   tabIndex="-1"><em>epino</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide" data-slick-index="3" aria-hidden="true" style={{width:"555px"}} tabIndex="-1"
//         role="tabpanel" id="slick-slide13">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Documentation Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>Really good template, easy to customize with a really good documentation. Many different
//                        example of pages.</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2686598"
//                                                                   tabIndex="-1"><em>pepsit36</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide" data-slick-index="4" aria-hidden="true" style={{width:"555px"}} tabIndex="-1"
//         role="tabpanel" id="slick-slide14">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Design Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>The theme and customer support are both top-tier! Thank you for saving us 30+ hours of
//                        work.</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2683519"
//                                                                   tabIndex="-1"><em>Its_Complete</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide" data-slick-index="5" aria-hidden="true" style={{width:"555px"}} tabIndex="-1"
//         role="tabpanel" id="slick-slide15">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Code Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>The BEST template we have bought on Themeforest in terms of design and code quality. Can't
//                        wait for their admin template!</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2653514"
//                                                                   tabIndex="-1"><em>KungWoo</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide" data-slick-index="6" aria-hidden="true" style={{width:"555px"}} tabIndex="-1"
//         role="tabpanel" id="slick-slide16">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Customer Support</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>TheThemeio is the best coder, which we ever worked in themeforest. Fast and on-demand email
//                        support. Good and quality work. Thank you for your help.</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2646286"
//                                                                   tabIndex="-1"><em>webserviz</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide slick-cloned" data-slick-index="7" aria-hidden="true" style={{width:"555px"}}
//         tabIndex="-1">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Design Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>Superb, comprehensive, professional. And most importantly, it makes me look good! Thank
//                        you.</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2784705"
//                                                                   tabIndex="-1"><em>coolrebel</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide slick-cloned" data-slick-index="8" aria-hidden="true" style={{width:"555px"}}
//         tabIndex="-1">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Code Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>Really well organized code, responsive customer service. excellent value for money.
//                        Recommended 100%</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2739368"
//                                                                   tabIndex="-1"><em>yorickgroeven</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide slick-cloned" data-slick-index="9" aria-hidden="true" style={{width:"555px"}}
//         tabIndex="-1">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Code Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>Excellent design and code quality. Infinite variations to be combined. Congrats for this
//                        job!</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2710320"
//                                                                   tabIndex="-1"><em>epino</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide slick-cloned" data-slick-index="10" aria-hidden="true" style={{width:"555px"}}
//         tabIndex="-1">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Documentation Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>Really good template, easy to customize with a really good documentation. Many different
//                        example of pages.</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2686598"
//                                                                   tabIndex="-1"><em>pepsit36</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide slick-cloned" data-slick-index="11" aria-hidden="true" style={{width:"555px"}}
//         tabIndex="-1">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Design Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>The theme and customer support are both top-tier! Thank you for saving us 30+ hours of
//                        work.</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2683519"
//                                                                   tabIndex="-1"><em>Its_Complete</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide slick-cloned" data-slick-index="12" aria-hidden="true" style={{width:"555px"}}
//         tabIndex="-1">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Code Quality</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>The BEST template we have bought on Themeforest in terms of design and code quality. Can't
//                        wait for their admin template!</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2653514"
//                                                                   tabIndex="-1"><em>KungWoo</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div>
//    <div className="slick-slide slick-cloned" data-slick-index="13" aria-hidden="true" style={{width:"555px"}}
//         tabIndex="-1">
//        <div>
//            <div className="p-5" style={{width:"100%", display:
//            "inline-block"}}>
//            <div className="card border hover-shadow-6">
//                <div className="card-body px-5">
//                    <div className="row">
//                        <div className="col-auto mr-auto">
//                            <h6><strong>Customer Support</strong></h6>
//                        </div>
//
//                        <div className="col-auto">
//                            <div className="rating mb-3">
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                                <label className="fa fa-star active"></label>
//                            </div>
//                        </div>
//                    </div>
//
//                    <p>TheThemeio is the best coder, which we ever worked in themeforest. Fast and on-demand email
//                        support. Good and quality work. Thank you for your help.</p>
//                    <p className="small-2 text-lighter mb-0">By <a className="text-inherit"
//                                                                   href="https://themeforest.net/ratings/2646286"
//                                                                   tabIndex="-1"><em>webserviz</em></a></p>
//
//                </div>
//            </div>
//        </div>
//    </div>
//    </div></div></div>
//    <ul className="slick-dots" role="tablist">
//        <li className="slick-active" role="presentation">
//            <button type="button" role="tab" id="slick-slide-control10" aria-controls="slick-slide10"
//                    aria-label="1 of 4" tabIndex="0" aria-selected="true">1
//            </button>
//        </li>
//        <li role="presentation">
//            <button type="button" role="tab" id="slick-slide-control11" aria-controls="slick-slide11"
//                    aria-label="2 of 4" tabIndex="-1">2
//            </button>
//        </li>
//        <li role="presentation">
//            <button type="button" role="tab" id="slick-slide-control12" aria-controls="slick-slide12"
//                    aria-label="3 of 4" tabIndex="-1">3
//            </button>
//        </li>
//        <li role="presentation">
//            <button type="button" role="tab" id="slick-slide-control13" aria-controls="slick-slide13"
//                    aria-label="4 of 4" tabIndex="-1">4
//            </button>
//        </li>
//        <li role="presentation">
//            <button type="button" role="tab" id="slick-slide-control14" aria-controls="slick-slide14"
//                    aria-label="5 of 4" tabIndex="-1">5
//            </button>
//        </li>
//        <li role="presentation">
//            <button type="button" role="tab" id="slick-slide-control15" aria-controls="slick-slide15"
//                    aria-label="6 of 4" tabIndex="-1">6
//            </button>
//        </li>
//        <li role="presentation">
//            <button type="button" role="tab" id="slick-slide-control16" aria-controls="slick-slide16"
//                    aria-label="7 of 4" tabIndex="-1">7
//            </button>
//        </li>
//    </ul>
//    </div>
//  );

}