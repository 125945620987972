import React, {useState, useEffect} from 'react';

import images from './components/AssetImg';

import {NavBar} from './components/NavBar';

import {MainPage} from './components/MainPage';
import {AboutUs} from './components/AboutUs';
import {Footer} from './components/Footer';

import {Route, Switch} from 'react-router-dom';


export const Home = () => {
    require('./home.css');

    const [navColor, setNavColor] = useState(true); // true = light, false = dark

    require('bootstrap/dist/css/bootstrap.min.css');

    require("./assets/css/page.css");
    require('./assets/css/style.css');
    require('./assets/css/custom.css');

    const urlPrefix = "";

    useEffect(() => {

        document.body.classList.add("granim-light");
        document.body.setAttribute("data-aos-delay", "0");
        document.body.setAttribute("data-aos-duration", "1500");
        document.body.setAttribute("data-aos-easing", "ease");
    }, []);


    return (

        <React.Fragment>

            <NavBar navColor={navColor} urlPrefix={urlPrefix}/>

            <Switch>
                <Route path="/" exact={true}>
                    <MainPage images={images} urlPrefix={urlPrefix} setNavColor={setNavColor}/>
                </Route>
                <Route path="/AboutUs" exact={true}>
                    <AboutUs images={images} urlPrefix={urlPrefix} setNavColor={setNavColor}/>
                </Route>
            </Switch>

            <Footer images={images} urlPrefix={urlPrefix}/>
        </React.Fragment>
    );


}

