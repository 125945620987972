import React from 'react';
import Slider from 'react-slick';

export const Slider1 = () => {
  const settings = {
        arrows:false,
        dots: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 0,
        swipeToSlide: true,
        draggable: true,


      };
  return (

      <Slider className="row gap-y" {...settings}>

          <div className="col" style={{width:"100%", display: "inline-block"}}>
            <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/form.html" tabIndex="-1">
              <p><i className="icon-envelope lead-6 text-muted"></i></p>
              <h6 className="mb-0">Form</h6>
            </a>
          </div>


          <div className="col" style={{width:"100%", display:
              "inline-block"}}>
              <a className="card card-body border hover-shadow-6 text-center py-6"
                 href="uikit/map.html" tabIndex="-1">
                  <p><i className="icon-map lead-6 text-muted"></i></p>
                  <h6 className="mb-0">Map</h6>
              </a>
          </div>

          <div className="col" style={{width:"100%", display:
              "inline-block"}}>
              <a className="card card-body border hover-shadow-6 text-center py-6"
                 href="uikit/social.html" tabIndex="-1">
                  <p><i className="icon-facebook lead-6 text-muted"></i></p>
                  <h6 className="mb-0">Social</h6>
              </a>
          </div>

          <div className="col" style={{width:"100%", display:
              "inline-block"}}>
              <a className="card card-body border hover-shadow-6 text-center py-6" href="block/cover.html"
                 tabIndex="0">
                  <p><i className="icon-picture lead-6 text-muted"></i></p>
                  <h6 className="mb-0">Cover</h6>
              </a>
          </div>

          <div className="col" style={{width:"100%", display:
              "inline-block"}}>
              <a className="card card-body border hover-shadow-6 text-center py-6" href="block/feature.html"
                 tabIndex="0">
                  <p><i className="icon-genius lead-6 text-muted"></i></p>
                  <h6 className="mb-0">Feature</h6>
              </a>
          </div>

          <div className="col" style={{width:"100%", display:
              "inline-block"}}>
              <a className="card card-body border hover-shadow-6 text-center py-6" href="block/blog.html"
                 tabIndex="0">
                  <p><i className="icon-browser lead-6 text-muted"></i></p>
                  <h6 className="mb-0">Blog</h6>
              </a>
          </div>

          <div className="col" style={{width:"100%", display:
              "inline-block"}}>
              <a className="card card-body border hover-shadow-6 text-center py-6" href="block/shop.html"
                 tabIndex="-1">
                  <p><i className="icon-basket lead-6 text-muted"></i></p>
                  <h6 className="mb-0">Shop</h6>
              </a>
          </div>

          <div className="col" style={{width:"100%", display:
              "inline-block"}}>
              <a className="card card-body border hover-shadow-6 text-center py-6" href="block/team.html"
                 tabIndex="-1">
                  <p><i className="icon-profile-male lead-6 text-muted"></i></p>
                  <h6 className="mb-0">Team</h6>
              </a>
          </div>

          <div className="col" style={{width:"100%", display:
              "inline-block"}}>
              <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/typography.html"
                 tabIndex="-1">
                  <p><i className="icon-pencil lead-6 text-muted"></i></p>
                  <h6 className="mb-0">Typography</h6>
              </a>
          </div>

          <div className="col" style={{width:"100%", display:
              "inline-block"}}>
              <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/card.html"
                 tabIndex="-1">
                  <p><i className="icon-video lead-6 text-muted"></i></p>
                  <h6 className="mb-0">Card</h6>
              </a>
          </div>

      </Slider>

  );
//  return (
//<div className="row gap-y slick-initialized slick-slider slick-dotted" data-provide="slider"
//     data-slides-to-show="3" data-slides-to-scroll="2" data-dots="true">
//
//    <div className="slick-list draggable">
//        <div className="slick-track" style={{opacity:"1", width:
//        "4370px", transform:"translate3d(-570px, 0px, 0px)"}}>
//        <div className="slick-slide slick-cloned" data-slick-index="-3" aria-hidden="true"
//             style={{width:"190px"}} tabIndex="-1">
//            <div>
//                <div className="col" style={{width:"100%", display:
//                "inline-block"}}>
//                <a className="card card-body border hover-shadow-6 text-center py-6"
//                   href="uikit/form.html" tabIndex="-1">
//                    <p><i className="icon-envelope lead-6 text-muted"></i></p>
//                    <h6 className="mb-0">Form</h6>
//                </a>
//            </div>
//        </div>
//    </div>
//    <div className="slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true"
//         style={{width:"190px"}} tabIndex="-1">
//        <div>
//            <div className="col" style={{width:"100%", display:
//            "inline-block"}}>
//            <a className="card card-body border hover-shadow-6 text-center py-6"
//               href="uikit/map.html" tabIndex="-1">
//                <p><i className="icon-map lead-6 text-muted"></i></p>
//                <h6 className="mb-0">Map</h6>
//            </a>
//        </div>
//    </div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true"
//     style={{width:"190px"}} tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6"
//           href="uikit/social.html" tabIndex="-1">
//            <p><i className="icon-facebook lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Social</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false"
//     style={{width:"190px"}} role="tabpanel" id="slick-slide00">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/cover.html"
//           tabIndex="0">
//            <p><i className="icon-picture lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Cover</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{width:"190px"}}
//     role="tabpanel" id="slick-slide01">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/feature.html"
//           tabIndex="0">
//            <p><i className="icon-genius lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Feature</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-active" data-slick-index="2" aria-hidden="false" style={{width:"190px"}}
//     role="tabpanel" id="slick-slide02">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/blog.html"
//           tabIndex="0">
//            <p><i className="icon-browser lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Blog</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide" data-slick-index="3" aria-hidden="true" style={{width:"190px"}} tabIndex="-1"
//     role="tabpanel" id="slick-slide03">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/shop.html"
//           tabIndex="-1">
//            <p><i className="icon-basket lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Shop</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide" data-slick-index="4" aria-hidden="true" style={{width:"190px"}} tabIndex="-1"
//     role="tabpanel" id="slick-slide04">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/team.html"
//           tabIndex="-1">
//            <p><i className="icon-profile-male lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Team</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide" data-slick-index="5" aria-hidden="true" style={{width:"190px"}} tabIndex="-1"
//     role="tabpanel" id="slick-slide05">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/typography.html"
//           tabIndex="-1">
//            <p><i className="icon-pencil lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Typography</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide" data-slick-index="6" aria-hidden="true" style={{width:"190px"}} tabIndex="-1"
//     role="tabpanel" id="slick-slide06">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/card.html"
//           tabIndex="-1">
//            <p><i className="icon-video lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Card</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide" data-slick-index="7" aria-hidden="true" style={{width:"190px"}} tabIndex="-1"
//     role="tabpanel" id="slick-slide07">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/form.html"
//           tabIndex="-1">
//            <p><i className="icon-envelope lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Form</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide" data-slick-index="8" aria-hidden="true" style={{width:"190px"}} tabIndex="-1"
//     role="tabpanel" id="slick-slide08">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/map.html"
//           tabIndex="-1">
//            <p><i className="icon-map lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Map</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide" data-slick-index="9" aria-hidden="true" style={{width:"190px"}} tabIndex="-1"
//     role="tabpanel" id="slick-slide09">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/social.html"
//           tabIndex="-1">
//            <p><i className="icon-facebook lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Social</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="10" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/cover.html"
//           tabIndex="-1">
//            <p><i className="icon-picture lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Cover</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="11" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/feature.html"
//           tabIndex="-1">
//            <p><i className="icon-genius lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Feature</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="12" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/blog.html"
//           tabIndex="-1">
//            <p><i className="icon-browser lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Blog</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="13" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/shop.html"
//           tabIndex="-1">
//            <p><i className="icon-basket lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Shop</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="14" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="block/team.html"
//           tabIndex="-1">
//            <p><i className="icon-profile-male lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Team</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="15" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/typography.html"
//           tabIndex="-1">
//            <p><i className="icon-pencil lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Typography</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="16" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/card.html"
//           tabIndex="-1">
//            <p><i className="icon-video lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Card</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="17" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/form.html"
//           tabIndex="-1">
//            <p><i className="icon-envelope lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Form</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="18" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/map.html"
//           tabIndex="-1">
//            <p><i className="icon-map lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Map</h6>
//        </a>
//    </div>
//</div>
//</div>
//<div className="slick-slide slick-cloned" data-slick-index="19" aria-hidden="true" style={{width:"190px"}}
//     tabIndex="-1">
//    <div>
//        <div className="col" style={{width:"100%", display:
//        "inline-block"}}>
//        <a className="card card-body border hover-shadow-6 text-center py-6" href="uikit/social.html"
//           tabIndex="-1">
//            <p><i className="icon-facebook lead-6 text-muted"></i></p>
//            <h6 className="mb-0">Social</h6>
//        </a>
//    </div>
//</div>
//</div></div></div>
//<ul className="slick-dots" role="tablist">
//    <li className="slick-active" role="presentation">
//        <button type="button" role="tab" id="slick-slide-control00" aria-controls="slick-slide00"
//                aria-label="1 of 4" tabIndex="0" aria-selected="true">1
//        </button>
//    </li>
//    <li role="presentation">
//        <button type="button" role="tab" id="slick-slide-control01" aria-controls="slick-slide02"
//                aria-label="2 of 4" tabIndex="-1">2
//        </button>
//    </li>
//    <li role="presentation">
//        <button type="button" role="tab" id="slick-slide-control02" aria-controls="slick-slide04"
//                aria-label="3 of 4" tabIndex="-1">3
//        </button>
//    </li>
//    <li role="presentation">
//        <button type="button" role="tab" id="slick-slide-control03" aria-controls="slick-slide06"
//                aria-label="4 of 4" tabIndex="-1">4
//        </button>
//    </li>
//    <li role="presentation">
//        <button type="button" role="tab" id="slick-slide-control04" aria-controls="slick-slide08"
//                aria-label="5 of 4" tabIndex="-1">5
//        </button>
//    </li>
//</ul>
//</div>
//
//
//  );
//
//
}