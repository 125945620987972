import React,{useEffect} from 'react';
import AOS from 'aos';

import {Typist} from './Typist';
import Granim from 'react-granim';


import {Slider1} from './Slider1';
import {Slider2} from './Slider2';
import {MyConstellation} from './Constellation';

export const MainPage = ({images,setNavColor,urlPrefix}) => {
  const prefix = (urlPrefix!==undefined) ? urlPrefix : console.log("urlPrefix not defined");
  const GranimState = {
                        "default-state": {
                            gradients: [
                                ['#667eea', '#764ba2'],
                                ['#00cdac', '#3cba92']
                            ],
                            transitionSpeed: 5000,
                            loop: true
                        }
                      }
  useEffect(()=>{
    setNavColor(true);
    AOS.init();
    AOS.refresh();

    Typist();
    MyConstellation();

  },[setNavColor]);

  return (
    <React.Fragment>
    <header className="header text-white h-fullscreen overflow-hidden" style={{backgroundImage:"linear-gradient(-45deg,#667eea 0%, #764ba2 100%)"}}>
        <canvas className="constellation" data-radius="0" width="1680" height="916"></canvas>
        <div className="container position-static">
            <div className="row align-items-center h-100">

                <div className="col-lg-7">
                    <h1 className="display-4 fw-500">

                      Specialize In <span className="fw-400 pl-2" id="typist" data-type='[ "Metal Roofs", "Metal Mansions", "Pole Barn Packages", "Demolition", "Construction" ]' data-period="80" />
                      <span className="typed-cursor">|</span>

                </h1>
                    <p className="lead mt-5 mb-7 mb-md-9 w-80">
                        Welcome to Fierce Construction. We are a full service Construction Company offering a wide range
                        of customized services to fit all of our customers’ needs. Take a look through our site to learn
                        more about what we have to offer, and don’t hesitate to reach out with any questions.
                        One of our team members would be happy to help.
                    </p>
                    <a className="btn btn-xl btn-round btn-success w-200 mr-3 px-6 d-none d-md-inline-block"
                       href="/Quotes">
                        Get Quotes
                    </a>
                    <a className="btn btn-xl btn-round btn-outline-light w-200 px-6" href="/Sites">Job Sites</a>
                </div>

                <div className="col-lg-5 d-none d-lg-block">
                </div>

            </div>

            <div className="d-none d-lg-block sample-blocks">
                <a href={`${prefix}/block/cover.html#block-2`}>
                    <img className="shadow-6 aos-init" src={images.AssetsImgPreviewBlock1Jpg} alt="..." data-aos="fade-up"
                         data-aos-delay="0" data-aos-offset="-10"/>
                </a>

                <a href={`${prefix}/block/team.html#block-6`}>
                    <img className="shadow-6 aos-init" src={images.AssetsImgPreviewBlock2Jpg} alt="..." data-aos="fade-up"
                         data-aos-delay="200" data-aos-offset="0"/>
                </a>

                <a href={`${prefix}/block/cover.html#block-5`}>
                    <img className="shadow-6 aos-init" src={images.AssetsImgPreviewBlock3Jpg} alt="..."
                         data-aos="fade-up" data-aos-delay="400" data-aos-offset="0"/>
                </a>

                <a href={`${prefix}/block/blog.html#block-1`}>
                    <img className="shadow-6 aos-init" src={images.AssetsImgPreviewBlock4Jpg} alt="..." data-aos="fade-up"
                         data-aos-delay="600" data-aos-offset="0"/>
                </a>

                <a href={`${prefix}/block/feature.html#block-8`}>
                    <img className="shadow-6 aos-init" src={images.AssetsImgPreviewBlock5Jpg} alt="..."
                         data-aos="fade-up" data-aos-delay="800" data-aos-offset="0"/>
                </a>

                <a href={`${prefix}/block/feature.html#block-13`}>
                    <img className="shadow-6 aos-init" src={images.AssetsImgPreviewBlock6Jpg} alt="..."
                         data-aos="fade-up" data-aos-delay="1000" data-aos-offset="0"/>
                </a>

                <a href={`${prefix}/block/shop.html#block-4`}>
                    <img className="shadow-6 aos-init" src={images.AssetsImgPreviewBlock7Jpg} alt="..."
                         data-aos="fade-up" data-aos-delay="1200" data-aos-offset="0"/>
                </a>

                <a href={`${prefix}/block/feature-text.html#block-3`}>
                    <img className="shadow-6 aos-init" src={images.AssetsImgPreviewBlock8Jpg} alt="..."
                         data-aos="fade-up" data-aos-delay="1400" data-aos-offset="0"/>
                </a>

                <a href={`${prefix}/block/pricing.html#block-6`}>
                    <img className="shadow-6 aos-init" src={images.AssetsImgPreviewBlock9Jpg} alt="..."
                         data-aos="fade-up" data-aos-delay="1700" data-aos-offset="0"/>
                </a>
            </div>


        </div>
    </header>

    <main className="main-content">

        <section id="section-demo" className="section overflow-hidden bg-gray">
            <div className="container">
                <header className="section-header">
                    <small>Demo</small>
                    <h2>Sample Landing Pages</h2>
                    <hr/>
                    <p className="lead">Apart from internal pages, we have designed several single sample pages to get
                        started with.</p>
                </header>

                <div className="row gap-y">
                    <div className="col-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="0">
                        <a className="card shadow-1 hover-shadow-7" href="demo/software-1.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemosoftware1Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">Software 1</h6>
                            </div>
                        </a>
                    </div>



                    <div className="col-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="150">
                        <a className="card shadow-1 hover-shadow-7" href="demo/marketing-1.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemomarketing1Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">Marketing 1</h6>
                            </div>
                        </a>
                    </div>


                    <div className="col-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="300">
                        <a className="card shadow-1 hover-shadow-7" href="demo/saas-1.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemosaas1Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">SaaS 1</h6>
                            </div>
                        </a>
                    </div>

                    <div className="col-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="0">
                        <a className="card shadow-1 hover-shadow-7" href="demo/marketing-2.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemomarketing2Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">Marketing 2</h6>
                            </div>
                        </a>
                    </div>



                    <div className="col-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="150">
                        <a className="card shadow-1 hover-shadow-7" href="demo/saas-4.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemosaas4Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">SaaS 4</h6>
                            </div>
                        </a>
                    </div>


                    <div className="col-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="300">
                        <a className="card shadow-1 hover-shadow-7" href="demo/saas-2.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemosaas2Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">SaaS 2</h6>
                            </div>
                        </a>
                    </div>


                    <div className="col-6 col-lg-4 aos-init"  data-aos="fade-up" data-aos-delay="0">
                        <a className="card shadow-1 hover-shadow-7" href="demo/software-3.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemosoftware3Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">Software 3</h6>
                            </div>
                        </a>
                    </div>

                    <div className="col-6 col-lg-4 aos-init"  data-aos="fade-up" data-aos-delay="150">
                        <a className="card shadow-1 hover-shadow-7" href="demo/saas-3.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemosaas3Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">SaaS 3</h6>
                            </div>
                        </a>
                    </div>


                    <div className="col-6 col-lg-4 aos-init"  data-aos="fade-up" data-aos-delay="300">
                        <a className="card shadow-1 hover-shadow-7" href="demo/finance-1.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemofinance1Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">Finance 1</h6>
                            </div>
                        </a>
                    </div>


                    <div className="col-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="0">
                        <a className="card shadow-1 hover-shadow-7" href="demo/software-2.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemosoftware2Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">Software 2</h6>
                            </div>
                        </a>
                    </div>

                    <div className="col-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="150">
                        <a className="card shadow-1 hover-shadow-7" href="demo/listing-1.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemolisting1Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">Listing 1</h6>
                            </div>
                        </a>
                    </div>


                    <div className="col-6 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="300">
                        <a className="card shadow-1 hover-shadow-7" href="demo/listing-2.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemolisting2Jpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">Listing 2</h6>
                            </div>
                        </a>
                    </div>


                    <div className="col-6 col-lg-4 aos-init"  data-aos="fade-up" data-aos-delay="0">
                        <a className="card shadow-1 hover-shadow-7" href="demo/app-finance.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemoappfinanceJpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">App — Finance</h6>
                            </div>
                        </a>
                    </div>

                    <div className="col-6 col-lg-4 aos-init"  data-aos="fade-up" data-aos-delay="150">
                        <a className="card shadow-1 hover-shadow-7" href="demo/app-social.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemoappsocialJpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">App — Social</h6>
                            </div>
                        </a>
                    </div>


                    <div className="col-6 col-lg-4 aos-init"  data-aos="fade-up" data-aos-delay="300">
                        <a className="card shadow-1 hover-shadow-7" href="demo/app-taxi.html">
                            <img className="card-img-top" src={images.AssetsImgPreviewDemoapptaxiJpg} alt="Demo"/>
                            <div className="card-body">
                                <h6 className="mb-0">App — Taxi</h6>
                            </div>
                        </a>
                    </div>


                </div>

            </div>
        </section>


    <section className="section pb-10">
        <div className="container">
            <div className="row gap-y align-items-center">

                <div className="col-lg-6">

                    <h2 className="mb-5">Blocks &amp; Elements</h2>
                    <p className="lead mb-0">This template includes hundreds of blocks to rapidly create your pages by
                        copy &amp; paste our block's code to your page, and backed by a lot of elements to develop your
                        own blocks.</p>

                    <br/>
                    <hr className="w-30 ml-0"/>
                    <br/>

                    <p className="mb-6 small">Explore or
                        <a href="block/index.html"> view all blocks</a>
                    </p>

                  <Slider1 />
                </div>

                <div className="col-lg-5 ml-lg-auto d-none d-lg-block">
                    <img src={images.AssetsImgPreviewBlocksandelementsPng} alt="..."/>
                </div>
            </div>
        </div>
    </section>


    <section className="section bg-gray">
        <div className="container">
            <header className="section-header">
                <small>Testimonials</small>
                <h2>Happy Customers</h2>
                <hr/>
                <p className="lead">Join thousands of satisfied customers using our template globally.</p>
            </header>


            <Slider2 />

        </div>
    </section>





    <section className="section">
        <div className="container">
            <header className="section-header">
                <h2>More Features</h2>
                <hr/>
                <p className="lead">We are so excited and proud of our product. It's really easy to create a landing
                    page for your awesome product.</p>
            </header>

            <div className="row gap-y text-center mt-7">

                <div className="col-md-6 col-xl-4 mb-6 feature-1">
                    <p className="feature-icon"><i className="icon-browser" style={{color:"#50a1ff"}}></i></p>
                    <p className="lead-1">Works great in all modern browsers</p>
                </div>

                <div className="col-md-6 col-xl-4 mb-6 feature-1">
                    <p className="feature-icon"><i className="icon-grid" style={{color:"#563d7c"}}></i></p>
                    <p className="lead-1">Based on Bootstrap framework 4</p>
                </div>

                <div className="col-md-6 col-xl-4 mb-6 feature-1">
                    <p className="feature-icon"><i className="icon-paintbrush" style={{color:"#ffbe00"}}></i></p>
                    <p className="lead-1">Elements with multiple colors</p>
                </div>

                <div className="col-md-6 col-xl-4 mb-6 feature-1">
                    <p className="feature-icon"><i className="icon-documents" style={{color:"#37b8af"}}></i></p>
                    <p className="lead-1">Very well code documentation</p>
                </div>

                <div className="col-md-6 col-xl-4 mb-6 feature-1">
                    <p className="feature-icon"><i className="icon-gift" style={{color:"#eb4a62"}}></i></p>
                    <p className="lead-1">Variety of sample landing pages</p>
                </div>

                <div className="col-md-6 col-xl-4 mb-6 feature-1">
                    <p className="feature-icon"><i className="icon-download" style={{color:"#46da60"}}></i></p>
                    <p className="lead-1">Free updates forever</p>
                </div>

            </div>

        </div>
    </section>


        <section className="section text-white py-8">
            <div className="overlay opacity-95">
              <Granim states={GranimState} direction="radial"/>
            </div>

            <div className="container">
                <header className="section-header">
                    <small><strong>BUILD IT</strong></small>
                    <h2 className="display-3 fw-400">About us</h2>
                    <hr/>
                    <p className="lead-2">If you're in need of a steel building for your home, office, farm, or commercial, consider working with Fierce Construction. For each of our services we have experienced professionals that will handle your project with detail and care. Let us know what you want, we’ll do our best to make it happen.</p>
                </header>


            </div>
        </section>

    </main>

    </React.Fragment>
    );

}