/*  Generated by carbonImgListGenerator.php */ 


  const images = {
   AssetsImgAppBadgeapplePng: require('../assets/img/app/badge-apple.png'),
   AssetsImgAppBadgegooglePng: require('../assets/img/app/badge-google.png'),
   AssetsImgAppMapmarkerPng: require('../assets/img/app/map-marker.png'),
   AssetsImgAppPhotoswipeDefaultskinPng: require('../assets/img/app/photoswipe/default-skin.png'),
   AssetsImgAppletouchiconPng: require('../assets/img/apple-touch-icon.png'),
   AssetsImgAvatar1Jpg: require('../assets/img/avatar/1.jpg'),
   AssetsImgAvatar10Jpg: require('../assets/img/avatar/10.jpg'),
   AssetsImgAvatar11Jpg: require('../assets/img/avatar/11.jpg'),
   AssetsImgAvatar2Jpg: require('../assets/img/avatar/2.jpg'),
   AssetsImgAvatar3Jpg: require('../assets/img/avatar/3.jpg'),
   AssetsImgAvatar4Jpg: require('../assets/img/avatar/4.jpg'),
   AssetsImgAvatar5Jpg: require('../assets/img/avatar/5.jpg'),
   AssetsImgAvatar6Jpg: require('../assets/img/avatar/6.jpg'),
   AssetsImgAvatar7Jpg: require('../assets/img/avatar/7.jpg'),
   AssetsImgAvatar8Jpg: require('../assets/img/avatar/8.jpg'),
   AssetsImgAvatar9Jpg: require('../assets/img/avatar/9.jpg'),
   AssetsImgAvatarAvatarsPng: require('../assets/img/avatar/avatars.png'),
   AssetsImgAvatarPlaceholderJpg: require('../assets/img/avatar/placeholder.jpg'),
   AssetsImgAvatarThethemeioPng: require('../assets/img/avatar/thethemeio.png'),
   AssetsImgBg1Jpg: require('../assets/img/bg/1.jpg'),
   AssetsImgBg10Jpg: require('../assets/img/bg/10.jpg'),
   AssetsImgBg11Jpg: require('../assets/img/bg/11.jpg'),
   AssetsImgBg12Jpg: require('../assets/img/bg/12.jpg'),
   AssetsImgBg13Jpg: require('../assets/img/bg/13.jpg'),
   AssetsImgBg14Jpg: require('../assets/img/bg/14.jpg'),
   AssetsImgBg2Jpg: require('../assets/img/bg/2.jpg'),
   AssetsImgBg3Jpg: require('../assets/img/bg/3.jpg'),
   AssetsImgBg4Jpg: require('../assets/img/bg/4.jpg'),
   AssetsImgBg5Jpg: require('../assets/img/bg/5.jpg'),
   AssetsImgBg6Jpg: require('../assets/img/bg/6.jpg'),
   AssetsImgBg7Jpg: require('../assets/img/bg/7.jpg'),
   AssetsImgBg8Jpg: require('../assets/img/bg/8.jpg'),
   AssetsImgBg9Jpg: require('../assets/img/bg/9.jpg'),
   AssetsImgBgPattern1Png: require('../assets/img/bg/pattern-1.png'),
   AssetsImgFaviconPng: require('../assets/img/favicon.png'),
   AssetsImgIconCloudPng: require('../assets/img/icon/cloud.png'),
   AssetsImgIconDogPng: require('../assets/img/icon/dog.png'),
   AssetsImgIconGithubPng: require('../assets/img/icon/github.png'),
   AssetsImgIconHomePng: require('../assets/img/icon/home.png'),
   AssetsImgIconLightPng: require('../assets/img/icon/light.png'),
   AssetsImgIconPcPng: require('../assets/img/icon/pc.png'),
   AssetsImgIconPlanetPng: require('../assets/img/icon/planet.png'),
   AssetsImgIconRocketPng: require('../assets/img/icon/rocket.png'),
   AssetsImgIconRocket2Png: require('../assets/img/icon/rocket2.png'),
   AssetsImgIconTaxiPng: require('../assets/img/icon/taxi.png'),
   AssetsImgLogoBootstrapPng: require('../assets/img/logo/bootstrap.png'),
   AssetsImgLogoTheadminPng: require('../assets/img/logo/theadmin.png'),
   AssetsImgLogoThedocsPng: require('../assets/img/logo/thedocs.png'),
   AssetsImgLogoTheguidePng: require('../assets/img/logo/theguide.png'),
   AssetsImgLogoThejobsPng: require('../assets/img/logo/thejobs.png'),
   AssetsImgLogoThesaaswpPng: require('../assets/img/logo/thesaas-wp.png'),
   AssetsImgLogoThesaasPng: require('../assets/img/logo/thesaas.png'),
   AssetsImgLogoTheshotsPng: require('../assets/img/logo/theshots.png'),
   AssetsImgLogoThesplashPng: require('../assets/img/logo/thesplash.png'),
   AssetsImgLogodarkPng: require('../assets/img/logo-dark.png'),
   AssetsImgLogodark1Png: require('../assets/img/logo-dark1.png'),
   AssetsImgLogolightPng: require('../assets/img/logo-light.png'),
   AssetsImgLogolight1Png: require('../assets/img/logo-light1.png'),
   AssetsImgOgimgJpg: require('../assets/img/og-img.jpg'),
   AssetsImgPartner1Png: require('../assets/img/partner/1.png'),
   AssetsImgPartner2Png: require('../assets/img/partner/2.png'),
   AssetsImgPartner3Png: require('../assets/img/partner/3.png'),
   AssetsImgPartner4Png: require('../assets/img/partner/4.png'),
   AssetsImgPartner5Png: require('../assets/img/partner/5.png'),
   AssetsImgPartner6Png: require('../assets/img/partner/6.png'),
   AssetsImgPartner7Png: require('../assets/img/partner/7.png'),
   AssetsImgPortfolio1Jpg: require('../assets/img/portfolio/1.jpg'),
   AssetsImgPortfolio10Jpg: require('../assets/img/portfolio/10.jpg'),
   AssetsImgPortfolio11Jpg: require('../assets/img/portfolio/11.jpg'),
   AssetsImgPortfolio12Jpg: require('../assets/img/portfolio/12.jpg'),
   AssetsImgPortfolio13Jpg: require('../assets/img/portfolio/13.jpg'),
   AssetsImgPortfolio14Jpg: require('../assets/img/portfolio/14.jpg'),
   AssetsImgPortfolio15Jpg: require('../assets/img/portfolio/15.jpg'),
   AssetsImgPortfolio16Jpg: require('../assets/img/portfolio/16.jpg'),
   AssetsImgPortfolio17Jpg: require('../assets/img/portfolio/17.jpg'),
   AssetsImgPortfolio18Jpg: require('../assets/img/portfolio/18.jpg'),
   AssetsImgPortfolio19Jpg: require('../assets/img/portfolio/19.jpg'),
   AssetsImgPortfolio2Jpg: require('../assets/img/portfolio/2.jpg'),
   AssetsImgPortfolio20Jpg: require('../assets/img/portfolio/20.jpg'),
   AssetsImgPortfolio3Jpg: require('../assets/img/portfolio/3.jpg'),
   AssetsImgPortfolio4Jpg: require('../assets/img/portfolio/4.jpg'),
   AssetsImgPortfolio5Jpg: require('../assets/img/portfolio/5.jpg'),
   AssetsImgPortfolio6Jpg: require('../assets/img/portfolio/6.jpg'),
   AssetsImgPortfolio7Jpg: require('../assets/img/portfolio/7.jpg'),
   AssetsImgPortfolio8Jpg: require('../assets/img/portfolio/8.jpg'),
   AssetsImgPortfolio9Jpg: require('../assets/img/portfolio/9.jpg'),
   AssetsImgPreviewBlock1Jpg: require('../assets/img/preview/block-1.jpg'),
   AssetsImgPreviewBlock2Jpg: require('../assets/img/preview/block-2.jpg'),
   AssetsImgPreviewBlock3Jpg: require('../assets/img/preview/block-3.jpg'),
   AssetsImgPreviewBlock4Jpg: require('../assets/img/preview/block-4.jpg'),
   AssetsImgPreviewBlock5Jpg: require('../assets/img/preview/block-5.jpg'),
   AssetsImgPreviewBlock6Jpg: require('../assets/img/preview/block-6.jpg'),
   AssetsImgPreviewBlock7Jpg: require('../assets/img/preview/block-7.jpg'),
   AssetsImgPreviewBlock8Jpg: require('../assets/img/preview/block-8.jpg'),
   AssetsImgPreviewBlock9Jpg: require('../assets/img/preview/block-9.jpg'),
   AssetsImgPreviewBlocksandelementsPng: require('../assets/img/preview/blocks-and-elements.png'),
   AssetsImgPreviewDemoappfinanceJpg: require('../assets/img/preview/demo-app-finance.jpg'),
   AssetsImgPreviewDemoappsocialJpg: require('../assets/img/preview/demo-app-social.jpg'),
   AssetsImgPreviewDemoapptaxiJpg: require('../assets/img/preview/demo-app-taxi.jpg'),
   AssetsImgPreviewDemofinance1Jpg: require('../assets/img/preview/demo-finance-1.jpg'),
   AssetsImgPreviewDemolisting1Jpg: require('../assets/img/preview/demo-listing-1.jpg'),
   AssetsImgPreviewDemolisting2Jpg: require('../assets/img/preview/demo-listing-2.jpg'),
   AssetsImgPreviewDemomarketing1Jpg: require('../assets/img/preview/demo-marketing-1.jpg'),
   AssetsImgPreviewDemomarketing2Jpg: require('../assets/img/preview/demo-marketing-2.jpg'),
   AssetsImgPreviewDemosaas1Jpg: require('../assets/img/preview/demo-saas-1.jpg'),
   AssetsImgPreviewDemosaas2Jpg: require('../assets/img/preview/demo-saas-2.jpg'),
   AssetsImgPreviewDemosaas3Jpg: require('../assets/img/preview/demo-saas-3.jpg'),
   AssetsImgPreviewDemosaas4Jpg: require('../assets/img/preview/demo-saas-4.jpg'),
   AssetsImgPreviewDemosoftware1Jpg: require('../assets/img/preview/demo-software-1.jpg'),
   AssetsImgPreviewDemosoftware2Jpg: require('../assets/img/preview/demo-software-2.jpg'),
   AssetsImgPreviewDemosoftware3Jpg: require('../assets/img/preview/demo-software-3.jpg'),
   AssetsImgPreviewDribbble1Jpg: require('../assets/img/preview/dribbble-1.jpg'),
   AssetsImgPreviewDribbble10Jpg: require('../assets/img/preview/dribbble-10.jpg'),
   AssetsImgPreviewDribbble11Jpg: require('../assets/img/preview/dribbble-11.jpg'),
   AssetsImgPreviewDribbble2Jpg: require('../assets/img/preview/dribbble-2.jpg'),
   AssetsImgPreviewDribbble3Jpg: require('../assets/img/preview/dribbble-3.jpg'),
   AssetsImgPreviewDribbble4Jpg: require('../assets/img/preview/dribbble-4.jpg'),
   AssetsImgPreviewDribbble5Jpg: require('../assets/img/preview/dribbble-5.jpg'),
   AssetsImgPreviewDribbble6Png: require('../assets/img/preview/dribbble-6.png'),
   AssetsImgPreviewDribbble7Png: require('../assets/img/preview/dribbble-7.png'),
   AssetsImgPreviewDribbble8Png: require('../assets/img/preview/dribbble-8.png'),
   AssetsImgPreviewFeaturetabletPng: require('../assets/img/preview/feature-tablet.png'),
   AssetsImgPreviewHeadercolorJpg: require('../assets/img/preview/header-color.jpg'),
   AssetsImgPreviewHeadergradientJpg: require('../assets/img/preview/header-gradient.jpg'),
   AssetsImgPreviewHeaderimageJpg: require('../assets/img/preview/header-image.jpg'),
   AssetsImgPreviewHeaderparallaxJpg: require('../assets/img/preview/header-parallax.jpg'),
   AssetsImgPreviewHeaderparticleJpg: require('../assets/img/preview/header-particle.jpg'),
   AssetsImgPreviewHeadersliderJpg: require('../assets/img/preview/header-slider.jpg'),
   AssetsImgPreviewHeadertypingJpg: require('../assets/img/preview/header-typing.jpg'),
   AssetsImgPreviewHeadervideoJpg: require('../assets/img/preview/header-video.jpg'),
   AssetsImgPreviewIconsetlinePng: require('../assets/img/preview/icons-etline.png'),
   AssetsImgPreviewIconsfontawesomePng: require('../assets/img/preview/icons-fontawesome.png'),
   AssetsImgPreviewIconsthemifyPng: require('../assets/img/preview/icons-themify.png'),
   AssetsImgPreviewIpad1Png: require('../assets/img/preview/ipad-1.png'),
   AssetsImgPreviewIpad2Png: require('../assets/img/preview/ipad-2.png'),
   AssetsImgPreviewLaptop1Png: require('../assets/img/preview/laptop-1.png'),
   AssetsImgPreviewLaptop2Png: require('../assets/img/preview/laptop-2.png'),
   AssetsImgPreviewMac1Png: require('../assets/img/preview/mac-1.png'),
   AssetsImgPreviewPhone1Png: require('../assets/img/preview/phone-1.png'),
   AssetsImgPreviewPhone2Png: require('../assets/img/preview/phone-2.png'),
   AssetsImgPreviewPhone3Png: require('../assets/img/preview/phone-3.png'),
   AssetsImgPreviewPhone4Png: require('../assets/img/preview/phone-4.png'),
   AssetsImgPreviewShot1Png: require('../assets/img/preview/shot-1.png'),
   AssetsImgPreviewShot2Png: require('../assets/img/preview/shot-2.png'),
   AssetsImgPreviewShot3Png: require('../assets/img/preview/shot-3.png'),
   AssetsImgPreviewShot4Png: require('../assets/img/preview/shot-4.png'),
   AssetsImgScreenshot1Jpg: require('../assets/img/screenshot/1.jpg'),
   AssetsImgScreenshot2Jpg: require('../assets/img/screenshot/2.jpg'),
   AssetsImgScreenshot3Jpg: require('../assets/img/screenshot/3.jpg'),
   AssetsImgScreenshot4Jpg: require('../assets/img/screenshot/4.jpg'),
   AssetsImgScreenshot5Jpg: require('../assets/img/screenshot/5.jpg'),
   AssetsImgScreenshot6Jpg: require('../assets/img/screenshot/6.jpg'),
   AssetsImgScreenshot7Jpg: require('../assets/img/screenshot/7.jpg'),
   AssetsImgScreenshot8Jpg: require('../assets/img/screenshot/8.jpg'),
   AssetsImgScreenshot9Jpg: require('../assets/img/screenshot/9.jpg'),
   AssetsImgShop1Png: require('../assets/img/shop/1.png'),
   AssetsImgShop10Jpg: require('../assets/img/shop/10.jpg'),
   AssetsImgShop11Jpg: require('../assets/img/shop/11.jpg'),
   AssetsImgShop12Jpg: require('../assets/img/shop/12.jpg'),
   AssetsImgShop13Jpg: require('../assets/img/shop/13.jpg'),
   AssetsImgShop14Jpg: require('../assets/img/shop/14.jpg'),
   AssetsImgShop15Jpg: require('../assets/img/shop/15.jpg'),
   AssetsImgShop16Jpg: require('../assets/img/shop/16.jpg'),
   AssetsImgShop17Jpg: require('../assets/img/shop/17.jpg'),
   AssetsImgShop18Jpg: require('../assets/img/shop/18.jpg'),
   AssetsImgShop19Png: require('../assets/img/shop/19.png'),
   AssetsImgShop2Png: require('../assets/img/shop/2.png'),
   AssetsImgShop20Png: require('../assets/img/shop/20.png'),
   AssetsImgShop21Png: require('../assets/img/shop/21.png'),
   AssetsImgShop22Png: require('../assets/img/shop/22.png'),
   AssetsImgShop23Jpg: require('../assets/img/shop/23.jpg'),
   AssetsImgShop3Png: require('../assets/img/shop/3.png'),
   AssetsImgShop4Png: require('../assets/img/shop/4.png'),
   AssetsImgShop5Png: require('../assets/img/shop/5.png'),
   AssetsImgShop6Png: require('../assets/img/shop/6.png'),
   AssetsImgShop7Png: require('../assets/img/shop/7.png'),
   AssetsImgShop8Png: require('../assets/img/shop/8.png'),
   AssetsImgShop9Png: require('../assets/img/shop/9.png'),
   AssetsImgThumb1Jpg: require('../assets/img/thumb/1.jpg'),
   AssetsImgThumb10Jpg: require('../assets/img/thumb/10.jpg'),
   AssetsImgThumb11Jpg: require('../assets/img/thumb/11.jpg'),
   AssetsImgThumb12Jpg: require('../assets/img/thumb/12.jpg'),
   AssetsImgThumb13Jpg: require('../assets/img/thumb/13.jpg'),
   AssetsImgThumb14Jpg: require('../assets/img/thumb/14.jpg'),
   AssetsImgThumb15Jpg: require('../assets/img/thumb/15.jpg'),
   AssetsImgThumb16Jpg: require('../assets/img/thumb/16.jpg'),
   AssetsImgThumb17Jpg: require('../assets/img/thumb/17.jpg'),
   AssetsImgThumb18Jpg: require('../assets/img/thumb/18.jpg'),
   AssetsImgThumb19Jpg: require('../assets/img/thumb/19.jpg'),
   AssetsImgThumb2Jpg: require('../assets/img/thumb/2.jpg'),
   AssetsImgThumb20Jpg: require('../assets/img/thumb/20.jpg'),
   AssetsImgThumb21Jpg: require('../assets/img/thumb/21.jpg'),
   AssetsImgThumb22Jpg: require('../assets/img/thumb/22.jpg'),
   AssetsImgThumb23Jpg: require('../assets/img/thumb/23.jpg'),
   AssetsImgThumb24Jpg: require('../assets/img/thumb/24.jpg'),
   AssetsImgThumb25Jpg: require('../assets/img/thumb/25.jpg'),
   AssetsImgThumb26Jpg: require('../assets/img/thumb/26.jpg'),
   AssetsImgThumb27Jpg: require('../assets/img/thumb/27.jpg'),
   AssetsImgThumb28Jpg: require('../assets/img/thumb/28.jpg'),
   AssetsImgThumb29Jpg: require('../assets/img/thumb/29.jpg'),
   AssetsImgThumb3Jpg: require('../assets/img/thumb/3.jpg'),
   AssetsImgThumb30Jpg: require('../assets/img/thumb/30.jpg'),
   AssetsImgThumb31Jpg: require('../assets/img/thumb/31.jpg'),
   AssetsImgThumb32Jpg: require('../assets/img/thumb/32.jpg'),
   AssetsImgThumb4Jpg: require('../assets/img/thumb/4.jpg'),
   AssetsImgThumb5Jpg: require('../assets/img/thumb/5.jpg'),
   AssetsImgThumb6Jpg: require('../assets/img/thumb/6.jpg'),
   AssetsImgThumb7Jpg: require('../assets/img/thumb/7.jpg'),
   AssetsImgThumb8Jpg: require('../assets/img/thumb/8.jpg'),
   AssetsImgThumb9Jpg: require('../assets/img/thumb/9.jpg'),
   AssetsImgVector1Png: require('../assets/img/vector/1.png'),
   AssetsImgVector10Png: require('../assets/img/vector/10.png'),
   AssetsImgVector11Png: require('../assets/img/vector/11.png'),
   AssetsImgVector12Png: require('../assets/img/vector/12.png'),
   AssetsImgVector13Png: require('../assets/img/vector/13.png'),
   AssetsImgVector14Png: require('../assets/img/vector/14.png'),
   AssetsImgVector15Png: require('../assets/img/vector/15.png'),
   AssetsImgVector16Png: require('../assets/img/vector/16.png'),
   AssetsImgVector17Png: require('../assets/img/vector/17.png'),
   AssetsImgVector18Png: require('../assets/img/vector/18.png'),
   AssetsImgVector19Png: require('../assets/img/vector/19.png'),
   AssetsImgVector2Png: require('../assets/img/vector/2.png'),
   AssetsImgVector20Png: require('../assets/img/vector/20.png'),
   AssetsImgVector21Png: require('../assets/img/vector/21.png'),
   AssetsImgVector22Png: require('../assets/img/vector/22.png'),
   AssetsImgVector23Png: require('../assets/img/vector/23.png'),
   AssetsImgVector24Png: require('../assets/img/vector/24.png'),
   AssetsImgVector25Png: require('../assets/img/vector/25.png'),
   AssetsImgVector26Png: require('../assets/img/vector/26.png'),
   AssetsImgVector27Png: require('../assets/img/vector/27.png'),
   AssetsImgVector28Png: require('../assets/img/vector/28.png'),
   AssetsImgVector3Png: require('../assets/img/vector/3.png'),
   AssetsImgVector4Png: require('../assets/img/vector/4.png'),
   AssetsImgVector5Png: require('../assets/img/vector/5.png'),
   AssetsImgVector6Png: require('../assets/img/vector/6.png'),
   AssetsImgVector7Jpg: require('../assets/img/vector/7.jpg'),
   AssetsImgVector8Png: require('../assets/img/vector/8.png'),
   AssetsImgVector9Jpg: require('../assets/img/vector/9.jpg'),
   AssetsImgVideo1Jpg: require('../assets/img/video/1.jpg'),
   AssetsImgGardenShed01Webp: require('../assets/img/GardenShed01.webp'),
   AssetsImgGardenShed02Gif: require('../assets/img/GardenShed02.gif'),
   AssetsImgGardenShed03Webp: require('../assets/img/GardenShed03.webp'),
   AssetsImgGardenShed04Webp: require('../assets/img/GardenShed04.webp'),
   AssetsImgRoof01Jpg: require('../assets/img/roof01.jpg'),
   AssetsImgRoof02Jpg: require('../assets/img/roof02.jpg'),
  };

export default images;