import React,{useEffect} from 'react';
import Slider from 'react-slick';
import CountUp from 'react-countup';
// import TrackVisibility from 'react-on-screen';

export const AboutUs = ({images,setNavColor}) => {

  useEffect(() => {
    setNavColor(false);
  },[setNavColor]);

  var settings = {
    autoplay: true,
    slidesToShow: 2,
    speed: 12000,
    autoplaySpeed: 0,
    pauseOnHover: false,
    arrows: false,
    infinite: true,
    slickPause:false,
    rtl: true,
    edgeFriction: 0.35,
    draggable: true,
    cssEase: 'linear',


  };

  return (
    <React.Fragment>

<header className="header">
    <div className="container">
        <div className="row align-items-center h-100">

            <div className="col-md-7 mx-auto">
                <h1>About us</h1>
                <p className="lead-3">Our company was founded in 2013 with a mission to create pretty templates for
                    great startups. We can combine beautiful, modern designs with clean, functional and high-performance
                    code to produce stunning websites. And best of all, we are super passionate about our work. Thought
                    does cognitive into follow and rationale annoyed.</p>
            </div>

        </div>
    </div>
</header>

<main className="main-content">

    <Slider {...settings}>
        <div className="p-2" style={{width:"100%", display:
        "inline-block"}}>
          <div className="rounded bg-img h-400"
               style={{backgroundImage:`url(${images.AssetsImgThumb12Jpg})`}}></div>
        </div>


        <div className="p-2" style={{width:"100%", display:
        "inline-block"}}>
          <div className="rounded bg-img h-400"
               style={{backgroundImage:`url(${images.AssetsImgThumb13Jpg})`}}></div>
        </div>

        <div className="p-2" style={{width:"100%", display:
        "inline-block"}}>
          <div className="rounded bg-img h-400" style={{backgroundImage:`url(${images.AssetsImgThumb14Jpg})`}}></div>
        </div>

        <div className="p-2" style={{width:"100%", display:
        "inline-block"}}>
          <div className="rounded bg-img h-400" style={{backgroundImage:`url(${images.AssetsImgThumb15Jpg})`}}></div>
        </div>

  </Slider>

    <section id="section-mission" className="section">
        <div className="container">
            <div className="row gap-y align-items-center">

                <div className="col-md-7 mx-auto">
                    <h2>Our Mission</h2>
                    <p className="lead">We’re a team of creative and experienced designers and developers. We used to
                        work as freelancers and we know what do you need and more that, we know what do your customers
                        want from you.</p>

                    <br/>

                    <p>
                        <i className="ti-check text-success mr-2"></i>
                        <span> Deliver a high quality web design</span>
                    </p>

                    <p>
                        <i className="ti-check text-success mr-2"></i>
                        <span> Support our customers once signing a contract</span>
                    </p>

                    <p>
                        <i className="ti-check text-success mr-2"></i>
                        <span> Streamline an expanded array of web</span>
                    </p>

                    <p>
                        <i className="ti-check text-success mr-2"></i>
                        <span> Pursue web-enabled niche markets with professionals</span>
                    </p>
                </div>

            </div>
        </div>
    </section>


    <section className="section text-white" style={{backgroundColor:"#93a5cf"}}>
        <div className="container">
            <div className="row gap-y text-center">

                <div className="col-md-4">
                    <p className="small text-uppercase ls-2">Finished Projects</p>
                    <p className="lead-8 lh-1 fw-700 counted" data-provide="countup" data-from="0" data-to="42">
                    <CountUp start={0} end={42}>
                      {({ countUpRef, start }) => (
                      {countUpRef}

                      )}
                    </CountUp>
                    </p>
                </div>

                <div className="col-md-4">
                    <p className="small text-uppercase ls-2">Happy Customers</p>
                    <p className="lead-8 lh-1 fw-700 counted" data-provide="countup" data-from="0" data-to="5478">
                        <CountUp end={5478} /></p>
                </div>

                <div className="col-md-4">
                    <p className="small text-uppercase ls-2">User Satisfaction</p>
                    <p className="lead-8 lh-1 fw-700 counted" data-provide="countup" data-from="0" data-to="100"
                       data-prefix="%">%<CountUp end={100} /></p>
                </div>

            </div>
        </div>
    </section>


    <section className="section">
        <div className="container">
            <header className="section-header">
                <h2>What People Say</h2>
                <br/>
                <p className="lead">We waited until we could do it right. Then we did! Instead of creating a carbon
                    copy.</p>
            </header>

            <div className="row gap-y text-center">

                <div className="col-lg-4">
                    <blockquote className="blockquote">
                        <div><img className="avatar avatar-xl" src={images.AssetsImgAvatar5Jpg} alt="..."/></div>
                        <div className="fs-15 mt-6">When you innovate, you make mistakes. It is best to admit them
                            quickly, and get on with improving your other innovations.
                        </div>
                        <footer><em>Steve Jobs</em></footer>
                    </blockquote>
                </div>

                <div className="col-lg-4">
                    <blockquote className="blockquote">
                        <div><img className="avatar avatar-xl" src={images.AssetsImgAvatar2Jpg} alt="..."/></div>
                        <div className="fs-15 mt-6">Design everything on the assumption that people are not heartless or
                            stupid but marvelously capable, given the chance.
                        </div>
                        <footer><em>John Jones</em></footer>
                    </blockquote>
                </div>

                <div className="col-lg-4">
                    <blockquote className="blockquote">
                        <div><img className="avatar avatar-xl" src={images.AssetsImgAvatar3Jpg} alt="..."/></div>
                        <div className="fs-15 mt-6">Technology is just a tool. In terms of getting the kids working
                            together and motivating them, the teacher is the most important.
                        </div>
                        <footer><em>Bill Gates</em></footer>
                    </blockquote>
                </div>

            </div>

        </div>
    </section>


    <section className="section bg-gray py-6">
        <div className="container">

            <div className="partner partner-sm">
                <img src={images.AssetsImgPartner1Png} alt="partner 1"/>
                <img src={images.AssetsImgPartner2Png} alt="partner 2"/>
                <img src={images.AssetsImgPartner3Png} alt="partner 3"/>
                <img src={images.AssetsImgPartner4Png} alt="partner 4"/>
                <img src={images.AssetsImgPartner5Png} alt="partner 5"/>
                <img src={images.AssetsImgPartner6Png} alt="partner 6"/>
            </div>

        </div>
    </section>


    <section className="section">
        <div className="container">
            <header className="section-header">
                <small>People</small>
                <h2>Meet The Team</h2>
                <hr/>
                <p className="lead">Meet our small team that make those great products</p>
            </header>


            <div className="row gap-y">
                <div className="col-md-6 col-lg-3 team-1">
                    <a href="#tmpTag">
                        <img src={images.AssetsImgAvatar5Jpg} alt="..."/>
                    </a>
                    <h6>Morgan Guadis</h6>
                    <small>Co-Founder &amp; CEO</small>
                    <div className="social social-gray">
                        <a className="social-twitter" href="#tmpTag"><i className="fa fa-twitter"></i></a>
                        <a className="social-facebook" href="#tmpTag"><i className="fa fa-facebook"></i></a>
                        <a className="social-instagram" href="#tmpTag"><i className="fa fa-instagram"></i></a>
                    </div>
                </div>


                <div className="col-md-6 col-lg-3 team-1">
                    <a href="#tmpTag">
                        <img src={images.AssetsImgAvatar6Jpg} alt="..."/>
                    </a>
                    <h6>John Senating</h6>
                    <small>Co-Founder &amp; CTO</small>
                    <div className="social social-gray">
                        <a className="social-facebook" href="#tmpTag"><i className="fa fa-facebook"></i></a>
                        <a className="social-twitter" href="#tmpTag"><i className="fa fa-twitter"></i></a>
                        <a className="social-instagram" href="#tmpTag"><i className="fa fa-instagram"></i></a>
                    </div>
                </div>


                <div className="col-md-6 col-lg-3 team-1">
                    <a href="#tmpTag">
                        <img src={images.AssetsImgAvatar7Jpg} alt="..."/>
                    </a>
                    <h6>Sandi Hormez</h6>
                    <small>Lead Developer</small>
                    <div className="social social-gray">
                        <a className="social-facebook" href="#tmpTag"><i className="fa fa-facebook"></i></a>
                        <a className="social-twitter" href="#tmpTag"><i className="fa fa-twitter"></i></a>
                        <a className="social-instagram" href="#tmpTag"><i className="fa fa-instagram"></i></a>
                    </div>
                </div>


                <div className="col-md-6 col-lg-3 team-1">
                    <a href="#tmpTag">
                        <img src={images.AssetsImgAvatar8Jpg} alt="..."/>
                    </a>
                    <h6>Animor Tiruse</h6>
                    <small>Designer</small>
                    <div className="social social-gray">
                        <a className="social-facebook" href="#tmpTag"><i className="fa fa-facebook"></i></a>
                        <a className="social-twitter" href="#tmpTag"><i className="fa fa-twitter"></i></a>
                        <a className="social-instagram" href="#tmpTag"><i className="fa fa-instagram"></i></a>
                    </div>
                </div>
            </div>

        </div>
    </section>


    <section className="section text-center py-12" style={{backgroundImage:`url(${images.AssetsImgThumb15Jpg})`}}
             data-overlay="7">
        <div className="container">
            <h2 className="display-4 text-white mb-7"><strong>Join Our Team.</strong></h2>
            <a className="btn btn-lg btn-round btn-info" href="career.html">View Openings</a>
        </div>
    </section>


</main>



    </React.Fragment>

  );

}