import React, {useState, useEffect} from 'react';
import images from './AssetImg';
import Modal from 'react-modal';
import _ from 'lodash';

Modal.setAppElement('#root');

export const NavBar = ({navColor, urlPrefix}) => {
  const prefix = (urlPrefix !== undefined) ? urlPrefix : console.log("urlPrefix not defined");
  const [navBarSmallLeft, setNavBarSmallLeft] = useState(false);
  const [modalIsOpen, _setIsOpen] = React.useState(false);
  const [isEventListenerSet, setIsEventListenerSet] = React.useState(false);
  const [menuFloat, setMenuFloat] = useState(false);

  const myScrollStateRef = React.useRef(modalIsOpen);
  const setIsOpen = data => {
    myScrollStateRef.current = data;
    _setIsOpen(data);
  }

  const scrolledFlag = data => {
    const headerClasses = ["body-scrolled", "navbar-scrolled", "header-scrolled"]
    if(data) {
      headerClasses.map((i) => document.body.classList.remove(i));
    } else {
      headerClasses.map((i) => document.body.classList.add(i));
    }

  }



  useEffect(() => {

    const scrollListener = () => {

      const isTop = window.scrollY;
      //Ignore scroll nav top menu when modal is open
      if(myScrollStateRef.current) {
        return;
      }
      if (isTop < 20) {
        scrolledFlag(true);
        setMenuFloat(false);
      } else {
        scrolledFlag(false);
        setMenuFloat(true);
      }

    }

    if(!isEventListenerSet) {
      document.addEventListener('scroll',scrollListener);
      setIsEventListenerSet(true)
    }


    if (navBarSmallLeft) {
      document.body.classList.add("navbar-open");
    } else {
      document.body.classList.remove("navbar-open");
    }
  }, [navBarSmallLeft,isEventListenerSet,setIsEventListenerSet]);


  const menuItem = {
    "Demos": {
      link: "./demo",
      "Carport": {
        link: "./demo/Carport",
      },
      "Shed": {
        link: "./demo/Shed",
      },
      "Building": {
        link: "./demo/Building",

      },
      "Demolition": {
        link: "./demo/Demolition",
      },
    },
    "Follow Us": {
      link: "./Follow",
      "Facebook": {
        link: './Follow/Facebook',
      },
      "Twitter": {
        link: './Follow/Twitter',
      },
      "Instagram": {
        link: './Follow/Instagram'
      },
    },
    "About": {
      link: '/AboutUs'
    }

  };

  function setModal() {


    setIsOpen(!modalIsOpen);
    console.log(modalIsOpen);
    console.log(menuFloat);
    if(modalIsOpen && menuFloat) {
      console.log("Set modal is close")
      scrolledFlag(false);
    } else {
      console.log("Set modal is open")
      scrolledFlag(true);
    }


  }

  const customStyles = {
    content: {
      left: 'auto',
      position: 'absolute',
      minWidth: '600px',
      height: '100vh',
      top: '0',
      padding: '70px 50px 20px',
      maxWidth: '50%',
      right: '0',
      transition: 'opacity .5s ease',
      fontSize: '2vh',
      width: '23em',

    }
  };


  const GenSubNavItemColumnBreak = ({subMenuName, menuItems, count}) => {

//    const subkeys = Object.keys(menuItems).filter(i=>i!=='link');

    return (
      <a className="nav-link" href={menuItems.link}
         key={`column-break-${subMenuName}-${count}`}> {subMenuName.charAt(0).toUpperCase() + subMenuName.slice(1)}</a>
    );


  }


  const GenSubNavItem = ({subMenuItem, megaFlag}) => {
    var content = '';
    const subkeys = Object.keys(subMenuItem).filter(i => i !== 'link');

    const HelperLoop = ({bulk, subMenuItem}) =>
      bulk.map((item, i) => (
        <GenSubNavItemColumnBreak menuItems={subMenuItem} subMenuName={item} key={`gensubnav-main-${item}-${i}`}
                                  count={i}/>
      ), [subMenuItem]);

    const CheckSubElementForArrows = ({sub}) => {
      if (Object.keys(sub).filter(i => i !== 'link').length > 0) {
        return <span className="arrow"></span>;
      }
      return '';
    }

    const GenSubSubALinks = ({title, sub}) => {
      const subKeys = Object.keys(sub).filter(i => i !== 'link');
      if (subKeys.length > 0) {
        const subHelper = subKeys.map((title, i) => (
          <GenSubNavItemColumnBreak menuItems={sub} subMenuName={title} count={i}
                                    key={`gensubsubalink-main-${title}-${i}`}/>
        ), [sub]);
//<nav className="nav" key={`subsublink-${title}`}>
        return (
          <nav className="nav" key={`subsubalink-${title}`}>
            {subHelper}
          </nav>
        );
      } else {
        return '';
      }
    }


    if (megaFlag) {

      const chunks = _.chunk(subkeys, 10);

      const innercontent = chunks.map((bulk, i) => (
        <div className="col-lg" key={`col-lg-${i}`}>
          <nav className="nav flex-column">
            <li className="nav-item">

              <HelperLoop bulk={bulk} subMenuItem={subMenuItem} key={`main-helperloop-${i}`}/>

            </li>
          </nav>
        </div>
      ), [subMenuItem]);


      content = (
        <div className="container-fluid">
          <div className="row">
            {innercontent}
          </div>
        </div>
      );
    } else {

      content = subkeys.map((item, i) => (
        <li className="nav-item" key={`${item}-${i}`}>
          <a className="nav-link" href={subMenuItem[item].link}>{item.charAt(0).toUpperCase() + item.slice(1)}
            <CheckSubElementForArrows sub={subMenuItem[item]}/>
          </a>
          <GenSubSubALinks title={item} sub={subMenuItem[item]}/>
        </li>
      ), [subMenuItem]);
    }


    return content;

  }

// First level items, demo/pages/blog/shop/blocks
  const GenNavItem = ({oneItem}) => {

    const firstLvlMenu = Object.keys(oneItem);
    //Check second level menu item count.

    const NeedArrow = ({e}) => {
      if (e.length > 0)
        return <span className="arrow"/>;

      return '';
    }

    return firstLvlMenu.map((name, i, org) => {
      const subElementCount = Object.keys(oneItem[name]).filter(i=>i!=='link');
      const megaFlag = subElementCount.length > 11;

        return (<li className={`nav-item ${megaFlag ? 'nav-mega' : ''}`} key={name + i}>
          <a className="nav-link" href="#tmpTag">{name.toUpperCase()}
            <NeedArrow e={subElementCount} />
          </a>
          <ul className={`nav ${megaFlag ? 'px-lg-2 py-lg-4' : ''}`}>
            <GenSubNavItem subMenuItem={menuItem[name]} megaFlag={megaFlag} key={`gennav-main-${name}-${i}`}/>
          </ul>
        </li>)
      }
    );

  }


  return (
    <nav
      className={`navbar navbar-expand-lg navbar-${navColor ? 'light' : 'dark'} navbar-stick-dark ${menuFloat && !modalIsOpen ? 'stick' : ''}`}
      data-navbar="sticky">
      {navBarSmallLeft ?
        <div className="backdrop backdrop-navbar" onClick={() => setNavBarSmallLeft(!navBarSmallLeft)}/> : ''}
      <div className="container">

        <div className="navbar-left">
          <button className="navbar-toggler" type="button">

                        <span className="navbar-toggler-icon"
                              onClick={() => setNavBarSmallLeft(!navBarSmallLeft)}></span>

          </button>
          <a className="navbar-brand" href={prefix}>
            <img className="logo-dark" src={images.AssetsImgLogodark1Png} alt="logo"/>
            <img className="logo-light" src={images.AssetsImgLogolight1Png} alt="logo"/>
          </a>
        </div>

        <section className="navbar-mobile">
          <span className="navbar-divider d-mobile-none"></span>

          <ul className="nav nav-navbar">
            <GenNavItem oneItem={menuItem}/>
          </ul>
        </section>
        <span className={`d-none d-md-block text-nowrap p-3 font-weight-bold ${menuFloat && !modalIsOpen ? '' : 'text-white'}`}
              style={{textAlign: "right", lineHeight: "1.79em"}}>
       386-867-0867
      </span>
        <button className="btn btn-xs btn-round btn-success" onClick={setModal}>
          Learn More</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={setModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4>I'd like to learn more!</h4>

        <form>
          <input  className="form-control input" placeholder="First name" style={{fontFamily: "Open Sans, sans-serif",background: "transparent",border: "0", borderBottom: "2px solid #39464e"}}/>
          <br/>
          <input className="form-control input" placeholder="Last name" style={{border: "0", borderBottom: "2px solid #39464e"}}/>
          <br/>
          <input className="form-control input" placeholder="Email address" style={{border: "0", borderBottom: "2px solid #39464e"}}/>
          <br/>
          <input className="form-control input" placeholder="Company name" style={{border: "0", borderBottom: "2px solid #39464e"}}/>
          <br/>
          <button type="submit" className="form-control btn btn-primary">Submit</button>
        </form>
      </Modal>

    </nav>

  );

}
